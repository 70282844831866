import JSBI from 'jsbi'

// exports for external consumption
export type BigintIsh = JSBI | bigint | string

export enum ChainId {
  MAINNET = 56,
  TESTNET = 97
}

export enum TradeType {
  EXACT_INPUT,
  EXACT_OUTPUT
}

export enum Rounding {
  ROUND_DOWN,
  ROUND_HALF_UP,
  ROUND_UP
}

export const FACTORY_ADDRESS = {
  [ChainId.MAINNET]: '0xA70FF6cf5A330F9D4c697C27953D09B4518348A6',
  [ChainId.TESTNET]: '0x79436975DcD97893732BbE2422DB0137E977B0fD'
}

// export const INIT_CODE_HASH = '0xc7f24a213a5242f06ef67ceacbe69c94319427889cde21b4269af8b8afe52163'
// export const INIT_CODE_HASH = '0x273b6c5d4a2e1e12f680c1c82ac31e00b39f0f5578954a8a8b7dca84b98bf273'
export const INIT_CODE_HASH = {
  [ChainId.MAINNET]: '0xf4f67e071cc5d365e0d610e4fe65f518996a5e0cdc2031c3f4ac77c6e5808728',
  [ChainId.TESTNET]: '0x355c7eb3cf8d9317ff55f1d8e6e6b2c8067b97dcdbabab44ab2e194ca85c9783'
}

export const MINIMUM_LIQUIDITY = JSBI.BigInt(1000)

// exports for internal consumption
export const ZERO = JSBI.BigInt(0)
export const ONE = JSBI.BigInt(1)
export const TWO = JSBI.BigInt(2)
export const THREE = JSBI.BigInt(3)
export const FIVE = JSBI.BigInt(5)
export const TEN = JSBI.BigInt(10)
export const _100 = JSBI.BigInt(100)
export const FEES_NUMERATOR = JSBI.BigInt(9975)
export const FEES_DENOMINATOR = JSBI.BigInt(10000)

export enum SolidityType {
  uint8 = 'uint8',
  uint256 = 'uint256'
}

export const SOLIDITY_TYPE_MAXIMA = {
  [SolidityType.uint8]: JSBI.BigInt('0xff'),
  [SolidityType.uint256]: JSBI.BigInt('0xffffffffffffffffffffffffffffffffffffffffffffffffffffffffffffffff')
}
